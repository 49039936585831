import React from "react";
import Stack from "@layout/Stack/Stack";
import Main from "@layout/Main/Main";
import { Link } from "gatsby";
import Layout from "@layout/Layout";
import { Card, Text, Heading, Box } from "theme-ui";

const styles = {
  card: {
    position: `relative`,
    maxWidth: `750px`,
    mx: `auto`,
  },
  wrapper: {
    maxWidth: 500,
    textAlign: `center`,
    mx: `auto`,
    py: 3,
  },
  icons: {
    verticalAlign: "middle",
    mr: "0.5rem",
    fill: "success",
  },
  plane: {
    fontSize: `9rem`,
    color: `beta`,
  },
  wind: {
    fontSize: `7rem`,
    color: `omegaLight`,
    transform: `rotate(120deg)`,
    mt: `0.5rem`,
    ml: `-3rem`,
  },
  link: {
    maxWidth: 300,
    mx: `auto`,
    mt: 4,
    " a": {
      color: "heading",
      textDecoration: "underline",
    },
  },
};

export default function ThankYou() {
  return (
    <Layout>
      <Box sx={{ mt: "10rem", mb: 4, p: [3, 0] }}>
        <Stack>
          <Main>
            <Card variant="paper" sx={styles.card}>
              <Box sx={styles.wrapper}>
                <Heading variant="h2">Köszi!</Heading>
                <Box sx={styles.icons}>
                  <svg width={60} height={60} viewBox="0 0 24 24">
                    <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z" />
                    <path d="M9.999 13.587L7.7 11.292l-1.412 1.416 3.713 3.705 6.706-6.706-1.414-1.414z" />
                  </svg>
                </Box>
                <Text variant="p">
                  Köszönjük, hogy kapcsolatba léptél velünk. 24 órán belül vállaszolunk!
                </Text>
                <Box sx={styles.link}>
                  <Link to="/">Vissza a főoldalra</Link>
                </Box>
              </Box>
            </Card>
          </Main>
        </Stack>
      </Box>
    </Layout>
  );
}
